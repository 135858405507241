<template>
  <div class="flex w-full justify-around items-center bg-white">
    <!-- <div class="flex flex-col text-center mx-3">
      <p>Admin panel</p>
      Roman Parubochyi (photography)
    </div> -->

    <div class="flex-grow text-center mx-auto">
      <router-link :to="{ name: 'home'}">Home</router-link> | 
      <router-link to="/portfolio">Portfolio</router-link>
    </div>
    <div class="flex-none">
      <button
        @click.prevent="logout"
        type="submit"
        class="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3"
      >
        Log Out
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminMenu",
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="postcss">
.admin-menu .router-link-active.router-link-exact-active {
  @apply bg-gray-100 text-gray-700;
}
</style>
