<template>
  <div class="flex flex-col bg-gray-100 h-screen">
    <Menu />
    <router-view class="flex flex-col flex-grow relative pt-10" :key="$route.path" />
  </div>
</template>

<script>
import Menu from "@/components/admin/Menu";

export default {
  name: "admin",
  components: {
    Menu
  },
};
</script>

<style></style>
